import React from 'react';
import './DownloadButtons.scss';
import playLogo from '../../assets/play_store.svg';
import appleLogo from '../../assets/app_store.svg';


function DownloadButtons() {
  return (
    <div className="download-buttons">
        <a
            className="dl-btn"
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/app/id6468910931"
        >
            <img src={appleLogo} alt="Apple Logo"/>
        </a>
        <a
            className="dl-btn"
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.chronotrade"
        >
            <img src={playLogo} alt="Google Logo"/>
        </a>
    </div>
  );
}

export default DownloadButtons;


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyARO99_pUB_VAQNnRqv8Do9s9Ev53C94Zs",
  authDomain: "chronotrade-a2192.firebaseapp.com",
  projectId: "chronotrade-a2192",
  storageBucket: "chronotrade-a2192.appspot.com",
  messagingSenderId: "873853580908",
  appId: "1:873853580908:web:e3c7b85aa7493bfbf94a49",
  measurementId: "G-ZR27V3PPYZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

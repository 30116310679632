import React, { useState, useEffect, useRef } from 'react';
import './Header.scss';
import { Link } from "react-router-dom";
import DownloadButtons from './download_buttons/DownloadButtons.js';

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();
  const menuButtonRef = useRef();

  const handleClickOutside = (event) => {
    if (
        menuRef.current
        && !menuRef.current.contains(event.target)
        && !menuButtonRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="header">
      <div className="left-section">
        <img src="logo.png" alt="Logo" className="logo" />
        <h1 className="title">
            <Link to="/">Chronotrade</Link>
        </h1>
      </div>
      <div ref={menuRef} className={isMenuOpen ? 'center-section open' : 'center-section'}>
        <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
        <a href="/#howitworks" onClick={() => setMenuOpen(false)}>How it works</a>
        <Link to="/contact" onClick={() => setMenuOpen(false)}>Contact us</Link>
        <DownloadButtons/>
      </div>
      <div className="right-section">
        <button ref={menuButtonRef} className="menu-button" onClick={() => setMenuOpen(!isMenuOpen)}>☰</button>
        <DownloadButtons/>
      </div>
    </div>
  );
}

export default Header;

import React from 'react';
import './Footer.scss';
import { Link } from "react-router-dom";
import DownloadButtons from './download_buttons/DownloadButtons.js';


function Footer() {
  return (
    <div className="footer-cont">
      <div className="social-links">
        <Link to="https://www.facebook.com/profile.php?id=61555595180358" target="_blank">
            <img src="fb.png" alt="Logo" className="logo" />
        </Link>
        <Link to="https://www.instagram.com/thechronotrade" target="_blank">
            <img src="insta.png" alt="Logo" className="logo" />
        </Link>
        <Link to="https://www.tiktok.com/@chronotrade" target="_blank">
            <img src="tiktok.png" alt="Logo" className="logo" />
        </Link>
        <Link to="https://twitter.com/TheChronotrade" target="_blank">
            <img src="x.png" alt="Logo" className="logo" />
        </Link>
        <Link to="https://www.youtube.com/channel/UChG4iv4gqjMNOmlK1_HA9Uw" target="_blank">
            <img src="yt.png" alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="footer">
          <div className="footer-links">
            <Link to="/contact">Contact us</Link>
            <Link to="/terms">Terms and Conditions</Link>
            <Link to="/privacy">Privacy Policy</Link>
          </div>
          <strong>&copy; 2023 CHRONOTRADE P.C. All rights reserved.</strong>
          <DownloadButtons/>
      </div>
    </div>
  );
}

export default Footer;


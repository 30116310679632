import './Terms.scss';

function Terms() {
  return (
    <div className="Terms">
      <h1>TERMS AND CONDITIONS</h1>

        <p>Welcome to the application "Chronotrade" (hereinafter "the application"), owned by the company under the trade name "CHRONOTRADE P.C." numbered at the general commercial registry 170066401000 (hereinafter "the Company" or "We"). Please carefully read the following terms and conditions governing this application and in case you disagree even with few of them, please do not proceed with the use of the application.</p>

        <p>By using our application, you imply having read and accepted the present terms and conditions of use without reservations. Please check this page each time you visit/use our application, as these terms and conditions may be modified or updated by the Company whenever deemed necessary.</p>

        <h2>Member Account</h2>

        <p>The Company provides its users with member services, upon accepting the Terms and Conditions of the application, completing the data entry process and paying the relevant membership fee. When the user completes the registration process by filling in their country, phone number, photograph, name, email address, they will receive an email with a url in order to confirm and activate their account. Members-users are solely responsible to provide the Company with accurate and valid personal data and keep them updated as well as to use carefully their account and to sign out. They are also responsible for any act that is done under their name and generally their account. If a user later changes or deactivates the phone number having been provided during the registration procedure to the Company, they must update their account information to help prevent the Company from communicating with anyone who acquires their old number. Members-users agree to inform immediately the Company for any unauthorized use of their account and any possible violation. The Company shall by no means be held liable for any loss or damage caused by a Member-user who does not abide by the present terms. The Company reserves the right to deactivate member accounts that have been inactive for a long time.</p>

        <h2>Terms of Payment</h2>

        <p>Subscriptions on offer:</p>

        <table>
          <thead>
          <tr>
            <th>Duration</th>
            <th>Price</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>3 month trial</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Monthly</td>
            <td>€99 + local tax</td>
          </tr>
          </tbody>
        </table>

        <h2>Cancellation Policy</h2>

        <p>Once a subscription is paid, the company does not offer refunds on cancellations. This policy applies to all services and products provided by the Company, including but not limited to the subscriptions described above.</p>

        <h2>Liability Limitation</h2>

        <p>The Company makes every possible effort for the proper operation of the application. Although, no method of data processing via Internet or electronic storage can be absolutely safe, the Company has in place several digital security measures (eg. end-to-end encryption, data retention periods) so that this application is a safe environment for its users. However, it cannot guarantee that the pages, services and contents will be provided without any interruption or errors as well as that any defects will be corrected. Due to the nature and size of Internet, under no circumstances, negligence included, shall the Company be held responsible for any kind of damage that user will have suffered by accessing pages and services of the application, which they access on their own initiative.</p>

        <p>The content and information included in the application are offered to the user of the application and generally to internet's users and are provided "as is". In no circumstances shall they be considered as valid information or/and advices, nor do they conceal an inducement to attempt or not certain act. Therefore, users of the application, by using its services on their own initiative, undertake the relevant responsibility to cross-check the provided information. Certain information is provided by other users and / or third parties.</p>

        <p>The user of the application understands and accepts that, due to the fact that the application can be accessed by anyone all over the world, the Company is not possible to control the content (i.e. photographs of the watches on offer, information on the characteristics of the watches such as genuity, origin, performance etc.) that is uploaded to the application by its users. The Company does not carry out a previous control of the content that is uploaded by users. Thus, the Company is in no position to verify them or to guarantee their accuracy. However, it reserves the exclusive right (and not obligation) to refuse at its own discretion the uploading, transfer and deletion of any content. Each user remains fully responsible for assessing the correctness of the content posted on the application, regardless of whether the content is provided by a user of the application or any third party.</p>

        <p>The Company does not bear any responsibility, direct or indirect for any harm or damage (positive or negative) that may occur to anyone from the use of our application and / or information contained, uploaded, sent, transferred or made available therein in any other way by the users of the application, regardless of whether the relevant information is provided by the Company, by any of the other users of it or by a third party.</p>

        <p>Therefore, the Company is not liable and does not bear any responsibility for any risk or damage (positive / negative) suffered by the user from the use of the content of this application, including indicatively and not exclusively the information regarding the watches on offer/ at request, the information provided in the context of the users' conversation amongst them or by any others means of interaction.</p>

        <p>The Company reserves the right to alter of suspend temporarily or permanently part or all of its services with or without notice to users.</p>

        <h2>Third party websites</h2>

        <p>The Company does not control the availability, content, privacy policy and personal data, quality and completeness of other sites and pages to which it may refer through hyperlinks or may be provided by any of the users under their profile/account. The Company shall not be considered in no way that it endorses and accepts the content or the services of these sites and pages to which it refers or is connected with them in any way. Accordingly, the Company is not responsible for any communication among the users of the application with third parties or for any commercial transaction, its terms, as well as for the quality of the goods that may arise from their relationship. The transaction and its terms are specified by the user and the goods providers, who are bound by them.</p>

        <h2>Copyrights - Prohibition of Republication</h2>

        <p>The entire content of the information and the services that appear on the application belong exclusively to the Company or to its respective owner and are protected by the intellectual and industrial property laws. All content on this application uploaded by the Company, including insignia, marks, images, graphics, photographs, drawings, texts, etc. constitute the intellectual property of the Company and are protected under the relevant provisions of Greek law, European law and international conventions or intellectual property of third parties for which the Company has been licensed for its own exclusive use and for the operation of its application.</p>

        <p>Any copying, transfer or creation of derivative work based on this content or misleading the public regarding the actual provision of the application is prohibited. Reproduction, re-publishing, uploading, communication, dissemination or transmission or any other use of the content in any way or for any commercial or other purposes is permitted only with the prior written consent of the Company or any other copyright holder. The names, images, logos and distinctive features listed and describing the branded application or the products or services of the Company or third parties constitute assets of the Company or third parties respectively, protected by the relevant laws about trademarks.</p>
    </div>
  );
}

export default Terms;

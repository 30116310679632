import './Contact.scss';

function Contact() {
  return (
    <div className="contact-container">
        <div className="Contact">
          <div className="contact-title">VIP Customer Care</div>
          <a href="mailto:sales@thechronotrade.com" className="contact-link">support@thechronotrade.com</a>
          <div className="contact-title ct">Scan to download the app</div>
          <img src="store_qr.png" alt="qr_code"/>
        </div>
    </div>
  );
}

export default Contact;
